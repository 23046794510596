import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import Metas from "../components/Metas"
import ArticleListItem from "../components/ListItems/ArticleListItem"
import HtmlParser from "../components/HtmlParser"
import PageHeader from "../components/PageHeader"
import { getParagraph } from "../helpers/paragraph-helpers"

const Article = ({ data, location }) => {
  const paragraphs = data.node.relationships.paragraphs.map(node => {
    return getParagraph(node, false)
  })

  return (
    <Layout contentType={data.node.internal.type}>
      <Metas title={data.node.title} location={location} />
      <PageHeader title={data.node.title} />
      <section className="section page-content">
        <div className="container mx-auto px-4">
          {data.node.relationships.image && (
            <div className="block figure text-center mb-8 lg:px-24">
              <figure className="relative">
                <GatsbyImage
                  image={
                    data.node.relationships.image.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  alt={data.node.title}
                />
              </figure>
            </div>
          )}

          {data.node.body && (
            <div
              className="block content mb-8 lg:px-24"
              data-typesense-field="body"
            >
              <HtmlParser html={data.node.body.value} />
            </div>
          )}

          <div className="paragraphs-list">{paragraphs}</div>
        </div>
      </section>

      {data.otherArticles && (
        <section className="section page-content-suggestion">
          <div className="container mx-auto px-4">
            <h2 className="title h2">
              <span>À lire aussi</span>
            </h2>
            <div className="columns grid grid-cols-1 md:grid-cols-2 gap-12">
              {data.otherArticles.nodes.map((node, index) => (
                <div key={index} className={`column`}>
                  <ArticleListItem content={node} />
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </Layout>
  )
}

export default Article

export const query = graphql`
  query ($internalId: Int!) {
    node: nodeArticle(
      status: { eq: true }
      drupal_internal__nid: { eq: $internalId }
    ) {
      title
      body {
        value
      }
      changed
      internal {
        type
      }
      relationships {
        paragraphs: field_paragraphes {
          type: __typename
          ... on Node {
            ...TextParagraph
            ...BannerParagraph
            ...HighlightedParagraph
            ...ListParagraph
            ...FilesMultiuploadParagraph
            ...AccordionParagraph
            ...CollapseParagraph
            ...ImageParagraph
            ...ImageGalleryParagraph
          }
        }
        image: field_image {
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 992
                height: 425
                quality: 75
                transformOptions: { cropFocus: CENTER }
                layout: CONSTRAINED
              )
            }
          }
        }
        tags: field_tags {
          name
          color: field_color
          path {
            alias
          }
        }
      }
    }
    otherArticles: allNodeArticle(
      limit: 2
      filter: {
        status: { eq: true }
        drupal_internal__nid: { ne: $internalId }
      }
      sort: { fields: created, order: DESC }
    ) {
      nodes {
        title
        body {
          summary
          value
        }
        path {
          alias
        }
        relationships {
          image: field_image {
            localFile {
              childImageSharp {
                gatsbyImageData(
                  width: 480
                  height: 304
                  quality: 75
                  transformOptions: { cropFocus: CENTER }
                  layout: CONSTRAINED
                )
              }
            }
          }
          tags: field_tags {
            name
            color: field_color
            path {
              alias
            }
          }
        }
      }
    }
  }
`
